
























































































































import {Store, TAwait} from '@/store/store'
import Component from 'vue-class-component'

import GenericForm from '@/components/forms/GenericForm.vue'

import {
  Home
} from '@/router';

interface AccountManager {
  id: number;
}

@Component({
  components: {
    GenericForm,
  },
  metaInfo: {
    title: 'Edit Account'
  }
})
export default class EditAccount extends Store {
  componentTitle = 'Edit Account';
  distAccount: any = {};
  billing: any = {};
  accountManager: TAwait<ReturnType<typeof Store.prototype.getDistributor>>['account_manager']['id'] | null = null
  svAdmins: any = []

  firstName: string = ''
  lastName: string = ''
  role: string = ''

  Home = Home
  
  async mounted(){
    if(!this.$route.params.id){
      this.$toast.error('Invalid ID Given')
      return
    }
    this.refresh()    
  }

  async refresh(){
    const id = parseInt(this.$route.params.id)

    const {name, last_name, role, business_profile, billing_details, account_manager} = await this.getDistributor(id).then((all) => {
      if(all)
        return all
      
      else throw "Was Not Able To Get Distributor Account Details"
    })

    this.firstName = name
    this.lastName = last_name
    this.role = role

    this.distAccount = business_profile
    this.billing = billing_details
    this.accountManager = account_manager?.id

    const listOfSvAdmins = await this.getSvAdmins()

    this.svAdmins = []
    listOfSvAdmins.forEach((admin) => {
      if(admin && !admin.is_pending){
        // Admin is not pending activation of their account.
        // Only add them is they are activated
        this.svAdmins.push(admin)
      }
    })
  }

  updateAccount(){
    if(!this.accountManager){
      this.$toast.error('An Account Manager Must Be Applied To the Account Before Progressing')
    }else{
      this.editDistributorAccount({id: +this.$route.params.id, accountManagerId: this.accountManager}).then(() =>{
        this.$toast.success('Distributor Account Updated Successfully')
        this.refresh()
      }).catch(() => this.$toast.error('There Was An Error Updating This Account'))
    }
    
  }

  deleteAccount(){
    this.$toast.error('Not Implemented Yet')
  }
}
